.cover {
    position: relative;
    padding-top: 185px;

    &:before {
        @include position(absolute, $top: 0, $left: 0);
        @include size(100%, 250px);
        content: "";
        background-color: $color-main;
        border-radius: 0px 0px 30px 30px;
        z-index: -1;
    }

    &__content {
        display: flex;
        background-color: $color-light;
        border-radius: $border-radius;
        height: auto;
    }

    &__image-wrapper {
        width: 550px;
        min-height: 290px;
        height: auto;
        overflow: hidden;
        position: relative;
        border-radius: $border-radius;
        font-size: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            border-radius: $border-radius;
            aspect-ratio: 550 / 290;
            min-height: 290px;
        }
    }

    &__informations {
        padding: 35px 30px 35px 35px;
        flex: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: auto 0;
    }

    &__title {
        @include flex($direction: column);
        font-family: $font-family--heading;
        font-size: toRem(40);
        font-weight: 700;
        line-height: toRem(40);
        margin: 0;
    }

    &__intro {
        font-size: toRem(18);
        font-weight: 600;
        line-height: toRem(25);
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {
        &__image-wrapper {
            min-width: 490px;
            width: 490px;
        }

        &__title {
            font-size: toRem(35);
            line-height: toRem(35);
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .cover {
        padding-top: 45px;
        
        &:before {
            height: 110px;
        }

        &__content {
            flex-direction: column;
        }

        &__image-wrapper {
            min-width: 100%;
            width: 100%;
            height: 200px;
            min-height: 200px;

            img {
                min-height: 200px;
            }
        }

        &__informations {
            padding: 25px 30px;
        }

        &__title {
            font-size: toRem(30);
            line-height: toRem(30);
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .cover {
        &__title {
            font-size: toRem(25);
            line-height: toRem(25);
        }

        &__informartions {
            gap: 10px;
        }
    }
}
