.scrollTopBtn {
    @include fixed($right: 20px, $bottom: 20px);
    z-index: 8;
    @include size(45px);
    padding: 0;
    margin: 0;
    background-color: $color-main;
    border-color: $color-main;
    border-radius: $border-radius;
    opacity: 0;
    transform: translateY(100px);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    transition: {
        property: opacity, transform, background-color, border-color;
        duration: .5s, .5s, $duration, $duration;
        timing-function: $timing;
    }

    &--show,
    &--show:focus  {
        opacity: 1;
        transform: translateY(0);
    }

    svg {
        fill: $color-white;
        transition: fill $duration $timing;
    }

    &:hover,
    &:focus {
        background-color: $color-second;
        border-color: $color-second;

        svg {
            fill: $color-white;
        }
    }
}