.footer {
    background-color: $color-main;
    border-radius: 30px 30px 0px 0px;
    color: $color-white;
    padding-bottom: 60px;

    &__container {
        position: relative;

        &:before {
            @include position(absolute, $bottom: -130px, $right: -90px);
            @include size(280px, 320px);
            content: "";
            background-image: url(/wp-content/themes/cormeilles-en-parisis/assets/src/images/sprite-images/home/forme_header.svg);
            background-repeat: no-repeat;
            z-index: 0;
            transform:scaleX(-1);
        }
    }

    &__infos {
        @include flex($alignItems: flex-start, $justifyContent: space-between);
        padding-top: 140px;
        position: relative;
        z-index: 1;
    }

    &__coordonnees {
        width: 270px;
    }

    &__horaires {
        width: 450px;
    }

    &__title {
        font-family: $font-family--heading;
        font-size: toRem(22);
        font-weight: 700;
        line-height: toRem(25);
    }

    &__text {
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(25);
        margin: 15px 0 0;
    }

    &__phone {
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(25);
        transition: .35s ease-in-out;
        text-decoration: underline;
        text-decoration-color: $color-bg--transparent;

        &:hover, &:focus {
            color: $color-white;
            text-decoration-color: $color-white;
        }
    }

    &__buttons {
        @include flex($direction: column);
        gap: 15px;
    }

    &__button {
        @include size(270px, 60px);
        padding: 0;
        margin: 0;
        border: 1px solid $color-white;

        &:hover, &:focus {
            background-color: $color-white;
            color: $color-dark;

            svg {
                fill: $color-dark;
            }
        }
    }

    &__menu {
        padding: 50px 0 35px;
        position: relative;
        z-index: 1;

        &__list {
            @include flex($wrap: wrap);
            gap: 40px;
            width: 100%;
        }

        &__link {
            color: $color-white;
            font-size: toRem(14);
            font-weight: 400;
            line-height: toRem(20);
            transition: .35s ease-in-out;
            text-decoration: underline;
            text-decoration-color: $color-bg--transparent;

            &:hover, &:focus {
                color: $color-white;
                text-decoration-color: $color-white;
            }
        } 
    }

    &__partenaires {
        @include flex($alignItems: center);
        gap: 100px;
        position: relative;
        z-index: 1;

        &--left, &--right {
            @include flex($alignItems: center);
            gap: 50px;
        }

        &--left {
            position: relative;

            &:before {
                @include position(absolute, $top: 0, $right: -50px);
                @include size(1px, 60px);
                content: "";
                background-color: $color-white;
                z-index: 0;
            }
        }
    }

    &__partenaire--link {
        transition: all $duration ease-in-out;

        &:hover, &:focus {
            transform: scale(0.87);
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer {
        &__container::before {
            right: -120px;
        }

        &__horaires {
            width: 280px;
            margin: 0 0 0 30px;
        }

        &__text, &__phone {
            font-size: toRem(14);
            line-height: toRem(20);
        }

        &__button {
            width: 250px;
        }

        &__menu {
            padding: 42px 0 40px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .footer {
        &__container::before {
            right: -90px;
            bottom: -135px;
        }

        &__infos {
            flex-wrap: wrap;
        }

        &__coordonnees {
            width: 50%;
        }

        &__horaires {
            width: calc(50% - 10px);
            margin: 0;
        }

        &__buttons {
            width: 100%;
            margin-top: 30px;
            column-gap: 25px;
            display: grid;
        }

        &__button {
            &:nth-child(1) {
                grid-column: 1;
                grid-row: 1;
            }

            &:nth-child(2) {
                grid-column: 2;
                grid-row: 1;
            }
        }

        &__menu {
            padding: 47px 0 40px;

            &__list {
                gap: 10px 40px;
            }
        }

        &__partenaires {
            flex-wrap: wrap;
            gap: 25px 80px;
    
            &--left, &--right {
                gap: 40px;
            }
    
            &--left {
                &:before {
                    height: 50px;
                    right: -40px;
                }

                .footer__partenaire {
                    &:nth-child(1) {
                        @include size(87.786px, 25px);
                    }
        
                    &:nth-child(2) {
                        @include size(116.25px, 45px);
                    }
                }
                
            }

            &--right {
                .footer__partenaire {
                    &:nth-child(1) {
                        @include size(96.154px, 50px);
                    }
        
                    &:nth-child(2) {
                        @include size(114.75px, 45px);
                    }

                    &:nth-child(3) {
                        @include size(43.846px, 50px);
                    }
                }
                
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .footer {
        &__container::before {
            bottom: unset;
            right: -190px;
            top: 115px;
        }
        
        &__infos {
            flex-direction: column;
        }

        &__coordonnees {
            width: 100%;
        }

        &__horaires {
            width: 100%;
            margin: 27px 0 0;
        }

        &__buttons {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 15px;
        }

        &__partenaires {
            gap: 25px 50px;
    
            &--left, &--right {
                gap: 25px;
            }
    
            &--left {
                &:before {
                    right: -25px;
                    top: -5px;
                }

                .footer__partenaire {
                    &:nth-child(1) {
                        @include size(87.786px, 25px);
                    }
        
                    &:nth-child(2) {
                        @include size(103.333px, 40px);
                    }
                }
                
            }

            &--right {
                flex-wrap: wrap;
                
                .footer__partenaire {
                    &:nth-child(1) {
                        @include size(86.538px, 45px);
                    }
        
                    &:nth-child(2) {
                        @include size(102px, 40px);
                    }

                    &:nth-child(3) {
                        @include size(43.846px, 50px);
                    }
                }
                
            }
        }
    }
}
