.breadcrumb {
    ul {
        display: block;
        min-height: 65px;
        padding: 20px 0;
    }

    li {
        position: relative;
        display: inline;
        color: $color-dark;
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(25);

        a {
            transition: .35s ease-in-out;
            text-decoration: underline;
            text-decoration-color: $color-bg--transparent;

            &:hover, &:focus {
                color: $color-dark;
                text-decoration-color: $color-dark;
            }
        }

        svg {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0;
            fill: $color-dark;
            vertical-align: text-top;
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .breadcrumb {
        li svg {
            margin: 0;
        } 
    }

}


// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        li svg {
            margin: 0 -1px;
        } 
    }

}
