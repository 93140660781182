.header {
    background-color: $color-main;
    @include position(fixed, $top: 0, $left: 0, $bottom: 0, $right: 0);
    height: 140px;
    z-index: 10;
    
    &__container {
       @include flex($justifyContent: space-between, $alignItems: flex-end);
       height: 100%;
    }

    &__link {
        position: relative;
        display: flex;
        z-index: 4;

        svg {
            fill: $color-white;
            transition: all $duration ease-in-out;
        }
    }

    &__title {
        font-size: 0;
        line-height: 0;
        @include size(0);
        display: flex;
    }

    &__content {
        @include flex($alignItems: center);
        gap: 15px;
        margin: 0 0 15px;
    }


    //*** MENU FIXE **//
    &--fixed {
        height: 100px;
        box-shadow: $shadow;
        border-radius: 0px 0px 30px 30px;

        .header {
            &__container {
                align-items: center;

                &:before {
                    @include position(absolute, $top: 0, $left: calc(50% - 730px));
                    @include size(280px, 100px);
                    content: "";
                    background-image: url(/wp-content/themes/cormeilles-en-parisis/assets/src/images/sprite-images/home/forme_header.svg);
                    background-repeat: no-repeat;
                    background-position-y: -100px;
                    z-index: 0;
                }
            }

            &__link svg {
                @include size(156px, 60px);
            }

            &__content {
                margin: 0;
            }
        }

        .tools {
            &__item {
                @include size(40px);
                border-radius: 5px;
            }

            &__searchbar {
                .search-container {
                    input {
                        border-radius: 5px;
                        height: 40px;
                        font-size: toRem(14);
                        line-height: toRem(20);
    
                        &::placeholder {
                            font-size: toRem(14);
                            line-height: toRem(20);
                        }
                    }
    
                    .search-svg {
                        width: 50px;
    
                        svg {
                            @include size(20px);
                        }
                    }
                }

                .searchResult {
                    margin: -26px 0 0;
                    padding: 3px 0 0;
                }
            }

            #accessconfig {
                height: 40px;
            }
        }

        .nav-main {
            &-buttons__menu {
                @include size(40px);
                border-radius: 5px;
    
                &--open .nav-main-buttons__menu__icon {
                    width: 20px;
    
                    &:before {
                        top: 6px;
                    }
    
                    &:after {
                        top: -6px;
                    }
                }
            }
            
            &--burger .menu {
                margin-top: 100px;

                &:after {
                    top: -140px;
                }
            }
        }
    }
}

//======================================================================================================
// Tools
//======================================================================================================
.tools {
    @include flex($alignItems: center);
    height: fit-content;
    gap: 5px;
    position: relative;
    z-index: 3;

    &__item {
        border-radius: $border-radius;
        @include size(65px);
        border: 1px solid $color-white;
        margin: 0;
        padding: 0;
        transition: all $duration ease-in-out;

        svg {
            @include size(40px);
            fill: $color-white;
        }

        &--search {
            display: none;
        }
    }

    &__searchbar {
        width: 495px;
        box-sizing: content-box;

        .search-container {
            input {
                @include size(100%, 65px);
                border-radius: $border-radius;
                border: 1px solid $color-white;
                background-color: transparent;
                margin: 0;
                padding: 20px;
                box-sizing: border-box;
                font-family: $font-family;
                font-size: toRem(16);
                font-weight: 600;
                line-height: toRem(25);
                font-style: italic;
                color: $color-white;
                position: relative;
                z-index: 2;
                transition: all $duration ease-in-out;
    
                &::placeholder {
                    font-family: $font-family;
                    font-size: toRem(16);
                    font-weight: 600;
                    line-height: toRem(25);
                    font-style: italic;
                    color: $color-white;
                    opacity: 1;
                }

                &::-webkit-search-cancel-button {
                    display: none;
                }

                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;
                    color: $color-dark;

                    &::placeholder {
                        color: $color-dark;
                    }
                }
            }
    
            .search-svg {
                background-color: transparent;
                z-index: 3;
                
                svg {
                    @include size(25px);
                    fill: $color-white;
                }
            }

            .search-fermer {
                display: none;
                position: absolute;
                width: 20px;
                height: 20px;
                right: 10px;
                z-index: 2;
            }

            &.focus {
                .search-svg {
                    display: none;
                }

                .search-fermer {
                    display: flex;
                }
            }
            
            &-open {
                input {
                    background-color: $color-white;
                    color: $color-dark;

                    &::placeholder {
                        color: $color-dark;
                    }
                }
                .search-svg {
                    svg {
                        fill: $color-dark;
                    }
                }
            }

            #js-searchbar:focus + .search-svg svg {
                fill: $color-dark;
            }
        }

        .searchResult {
            @include position(absolute, $top: 65px);
            width: 495px;
            transition: all $duration ease-in-out;

            li {
                transition: all $duration ease-in-out;

                a {
                    max-width: 70%;

                    &:hover, &:focus {
                        background-color: transparent;
                    }
                }
    
                a, .resultCPT {
                    font-family: $font-family--heading;
                    font-size: toRem(14);
                    font-weight: 400;
                    line-height: toRem(20);
                    color: $color-dark;
                }

                &:hover, &:focus {
                    background-color: $color-second!important;
                }
            }
        }
        
    }

    #accessconfig {
        height: 65px;
        transition: all $duration ease-in-out;
    }
}  


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        &__content {
            gap: 10px;
        }

        &--fixed {
            .header__container::before {
                left: calc(50% - 580px);
            }
        }
    }
    
    .tools {
        gap: 10px;

        &__searchbar {
            width: 425px;

            .searchResult {
                width: 425px;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .header {
        position: relative;

        &__link {
            z-index: 2;
        }

        &__content {
            gap: 20px;
        }
    }

    .tools {
        z-index: 2;
        gap: 20px;

        &__searchbar {
            display: none;
        }

        &__item {
            &--search {
                display: flex;

                svg {
                    @include size(25px);
                }
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .header {
        

        &__container {
            flex-wrap: wrap;
        }
    }
}

// 595
@media screen and (max-width: 595px) {
    .header {
        height: fit-content;
        padding-top: 40px;

        &__container {
            justify-content: center;
            gap: 40px;
        }
    }
}
