//======================================================================================================
// Accès rapides
//======================================================================================================

.access {
    padding: 185px 0 60px;
    background-color: $color-main;
    border-radius: 0px 0px 30px 30px;
    
    &__blocs {
        display: flex;
        gap: 55px;
        position: relative;

        &:before {
            @include position(absolute, $top: -35px, $left: -130px);
            @include size(280px, 320px);
            content: "";
            background-image: url(/wp-content/themes/cormeilles-en-parisis/assets/src/images/sprite-images/home/forme_header.svg);
            background-repeat: no-repeat;
            z-index: 0;
            display: block;
        }

        &.hide-before::before {
            display: none;
        }
    }

    &__bloc {
        @include flex($direction: column, $alignItems: center, $justifyContent: center);
        width: 105px;
        gap: 10px;
        position: relative;
        z-index: 1;

        svg {
            @include size(105px);
            background-color: $color-white;
            border-radius: 100px;
            transition: all 0.35s ease-in-out;

            path {
                transition: all 0.35s ease-in-out;
            }
        }

        p {
            font-size: toRem(16);
            font-weight: 700;
            line-height: toRem(20);
            color: $color-white;
            text-align: center;
            transition: all 0.35s ease-in-out;

            span {
                font-weight: 400;
            }
        }

        &:hover, &:focus {
            svg {
                background-color: $color-second;

                path {
                    fill: $color-white;
                }
            }
        }

        &--billetterie {
            @include size(200px, 155px);
            background-color: $color-second;
            border-radius: $border-radius;
            margin-left: 10px;
            gap: 3px;
            transition: all 0.35s ease-in-out;

            svg {
                background-color: transparent;
                @include size(60px);
                border-radius: 0;
                margin-top: -6px;
            }

            &:hover, &:focus {
                background-color: $color-light;

                svg {
                    background-color: transparent!important;
                    
                    path {
                        fill: $color-dark;
                    }
                }

                p {
                    color: $color-dark;
                }
            }
        }
    }
}

//======================================================================================================
// Actualités
//======================================================================================================

.news {
    position: relative;
    margin: 95px 0 100px;
    z-index: 1;

    &__container {
        @include flex($direction: column, $alignItems: flex-start);
        position: relative;
    }

    &__title {
        @extend %titleHome;
        color: $color-dark;
    }

    &__content {
        @include flex($direction: column);
        row-gap: 50px;
        margin-top: 35px;
    }

    &__items {
        @include flex($justifyContent: flex-end);
        column-gap: 50px;
    }

    &__link {
        &--big {
            @include flex($alignItems: center);
            column-gap: 30px;

            &:hover, &:focus {
                .news {
                    &__plus {
                        transform: translateX(10px);
                        color: $color-main;
                    }
                }
            }
        }

        &--small {
            width: 560px;
            @include flex($direction: column, $alignItems: flex-start);
            row-gap: 15px;
        }

        &:hover, &:focus {
            .news {
                &__image img {
                    transform: scale(1);
                }

                &__titlep--small {
                    color: $color-main;
                }
            }
        }
    }

    &__image {
        overflow: hidden;
        border-radius: $border-radius;

        &--big {
            @include size(650px, 300px);
            min-width: 650px;
        }

        &--small {
            @include size(100%, 260px);
            position: relative;
        }

        img {
            @include size(100%);
            object-fit: cover;
            transform: scale(1.05);
            transition: $duration;
        }
    }

    .notimg {
        background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-light;
        background-position: center;
        border-radius: $border-radius;

        &--big {
            @include size(650px, 300px);
        }

        &--small {
            @include size(100%, 260px);
            position: relative;
        }
    }

    &__infos {
        @include flex($direction: column, $alignItems: flex-end);
        max-width: 490px;
    }

    &__titlep {
        font-family: $font-family--heading;
        font-weight: 700;
        margin: 0;
        padding: 0;
        color: $color-dark;

        &--big {
            font-size: toRem(28);
            line-height: toRem(30);
            width: 100%;
        }

        &--small {
            font-size: toRem(25);
            line-height: toRem(30);
            transition: color $duration ease-in-out;
        }
    }

    &__intro {
        font-size: toRem(18);
        font-weight: 400;
        line-height: toRem(25); 
        margin: 10px 0 30px;
        color: $color-dark;
        width: 100%;
    }

    &__plus {
        @include flex($alignItems: center);
        gap: 10px;
        font-family: $font-family--heading;
        font-size: toRem(18);
        font-weight: 400;
        line-height: toRem(20);
        text-transform: uppercase;
        color: $color-dark;
        transition: all $duration ease-in-out;

        svg {
            @include size(20px);
            fill: $color-main;
            transition: all $duration ease-in-out;
        }
    }

    &__button {
        @extend %button;
        @include position(absolute, $top: -10px, $right: 15px);
        
        &:hover, &:focus {
            background-color: $color-light;
            border-color: $color-light;
            color: $color-dark;
    
            svg {
                fill: $color-dark;
            }
        }
    }
}

//======================================================================================================
// Agenda 
//======================================================================================================

.home-events {
    background-color: $color-main;
    color: $color-white;
    padding: 70px 0 180px;

    &__container {
        position: relative;

        &:before {
            @include position(absolute, $top: -45px, $left: -135px);
            @include size(210px, 340px);
            content: "";
            background-image: url(/wp-content/themes/cormeilles-en-parisis/assets/src/images/sprite-images/home/forme_agenda.svg);
            background-repeat: no-repeat;
            z-index: 0;
        }
    }

    &__top {
        margin: 0 0 35px;
        @include flex($alignItems: flex-end, $justifyContent: space-between);

        > div {
            @include flex($alignItems: center);
            gap: 15px;
        }
    }

    &__title {
        @extend %titleHome;
        color: $color-white;
    }

    &__navigation {
        @include flex($alignItems: center);
        gap: 10px;

        &--prev, &--next {
            @include size(60px!important);
            @include position(relative!important, $top: unset!important, $left: unset!important, $bottom: unset!important, $right: unset!important);
            @include flex($alignItems: center, $justifyContent: center);
            border: 1px solid $color-white;
            background-color: transparent;
            margin-top: 0!important;
            border-radius: $border-radius;
            transition: all $duration ease-in-out;
            cursor: pointer;
            z-index: 2!important;
            
            &:after {
                display: none;
            }

            svg {
                fill: $color-white;
                @include size(25px);
                transition: all $duration ease-in-out;
            }

            &.swiper-button-disabled {
                opacity: 0.6!important;
            }

            &:hover, &:focus {
                background-color: $color-white;

                svg {
                    fill: $color-main;
                }
            }
        }
    }

    &__swiper {
        height: 380px;
    }

    &__link {
        @include flex($direction: column);
        border-radius: $border-radius;
        transition: all $duration ease-in-out!important;

        &:hover, &:focus {
            .home-events {
                &__image img {
                    transform: scale(1);
                }

                &__date {
                    transform: translateX(10px);
                }
            }
        }
    }

    &__image {
        @include size(100%, 200px);
        overflow: hidden;
        border-radius: $border-radius;

        img {
            @include size(100%);
            object-fit: cover;
            transform: scale(1.05);
            transition: all $duration ease-in-out;
        }

        &--notimg {
            @include size(100%, 200px);
            background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-light;
            background-position: center;
            border-radius: $border-radius;
        }
    }

    &__infos {
        @include flex($direction: column, $justifyContent: flex-end);
        gap: 10px;
        height: 200px;
        padding: 30px;
        margin-top: -20px;
        box-sizing: border-box;
        border-radius: $border-radius;
        background: rgba(255, 255, 255, 0.10);
    }

    &__date {
        @include flex($alignItems: center);
        gap: 5px;
        font-family: $font-family--heading;
        font-size: toRem(22);
        font-weight: 400;
        line-height: toRem(25);
        text-transform: uppercase;
        color: $color-white;
        transition: all $duration ease-in-out;

        svg {
            @include size(20px);
            fill: $color-white;
            margin-right: 5px;
        }
    }

    &__title--small {
        font-family: $font-family--heading;
        font-size: toRem(25);
        font-weight: 700;
        line-height: toRem(30);
        color: $color-white;
        margin: 0;
        padding: 0;
    }

    &__button {
        @extend %button;
        background-color: $color-white;
        border-color: $color-white;
        color: $color-dark;

        svg {
            fill: $color-dark;
        }

        &:hover, &:focus {
            background-color: $color-second;
            border-color: $color-second;
            color: $color-white;

            svg {
                fill: $color-white;
            }
        }
    }
}

//======================================================================================================
// Conseils municipaux
//======================================================================================================

.conseils {
    margin-top: -100px;

    &__container {
        background-color: $color-second;
        height: 215px;
        border-radius: $border-radius;
        @include flex($alignItems: flex-end, $justifyContent: space-between);
        padding: 50px;
        position: relative;

        &:before {
            @include position(absolute, $top: 0, $left: 0);
            @include size(510px, 100%);
            content: "";
            background-image: url(/wp-content/themes/cormeilles-en-parisis/assets/src/images/sprite-images/home/img_conseils.jpg);
            background-repeat: no-repeat;
            border-radius: $border-radius 0 0 $border-radius;
            z-index: 0;
        }
        

        &:after {
            @include position(absolute, $top: 0, $left: 0);
            @include size(100%);
            content: "";
            background-color: rgba(0, 41, 78, 0.75);
            border-radius: $border-radius;
            z-index: 0;
        }
    }

    &__title {
        @include flex($alignItems: flex-end);
        gap: 40px;
        font-family: $font-family--heading;
        font-size: toRem(40);
        font-weight: 700;
        line-height: toRem(45);
        margin: 0;
        padding: 0;
        color: $color-white;
        position: relative;
        z-index: 1;

        svg {
            @include size(100px, 60px);
            fill: $color-white;
            margin: 0 0 10px;
        }
    }

    &__content {
        @include flex($alignItems: center);
        gap: 35px;
        position: relative;
        z-index: 1;
        margin-left: 10px;
    }

    &__item {
        @include flex($direction: column, $justifyContent: flex-end);
        gap: 10px;
        @include size(420px, 115px);
        background-color: $color-white;
        border-radius: $border-radius;
        padding: 20px;
        box-sizing: border-box;
        transition: all $duration ease-in-out;

        &:hover, &:focus {
            background-color: $color-light;
            color: $color-dark;

            .conseils {
                &__date {
                    color: $color-dark;
                    transform: translateX(10px);
                    
                    svg {
                        fill: $color-dark;
                    }
                }

                &__title--small {
                    color: $color-dark;
                }
            }
        }
    }

    &__date {
        @include flex($alignItems: center);
        gap: 10px;
        font-family: $font-family--heading;
        font-size: toRem(20);
        font-weight: 400;
        line-height: toRem(25);
        text-transform: uppercase;
        transition: all $duration ease-in-out;

        svg {
            fill: $color-dark;
            @include size(20px);
            transition: fill $duration ease-in-out;
        }
    }

    &__title--small {
        font-family: $font-family--heading;
        font-size: toRem(24);
        font-weight: 700;
        line-height: toRem(20);
        color: $color-dark;
        margin: 0;
        padding: 0;
        transition: color $duration ease-in-out;
    }

    &__button {
        @include flex($direction: column, $alignItems: center, $justifyContent: center);
        gap: 10px;
        @include size(200px, 115px);
        border: 1px solid $color-white;
        border-radius: $border-radius;
        font-family: $font-family;
        font-size: toRem(14);
        font-weight: 700;
        line-height: toRem(20);
        text-transform: uppercase;
        text-align: center;
        color: $color-white;
        position: relative;
        z-index: 1;
        transition: all $duration ease-in-out;

        svg {
            @include size(25px);
            fill: $color-white;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            background-color: $color-light;
            color: $color-light;
            color: $color-dark;

            svg {
                fill: $color-dark;
            }
        }
    }
}

//======================================================================================================
// Documents
//======================================================================================================

.home-documents {
    margin: 100px 0;

    &__container {
        position: relative;
    }

    &__title {
        @extend %titleHome;
        color: $color-dark;
    }

    &__items {
        @include flex($alignItems: flex-start, $justifyContent: space-between);
        margin: 35px 0 0;
    }

    &__item {
        display: flex;
        gap: 30px;

        &:first-child {
            .home-documents__image {
                @include size(400px, 270px);
            }
        }

        &:last-child {
            .home-documents__image {
                @include size(230px, 270px);
            }
        }
    }

    &__image {
        border-radius: $border-radius;

        img {
            @include size(100%);
            object-fit: cover;
            border-radius: $border-radius;
        }
    }

    &__content {
        width: 220px;
        margin: 40px 0;
    }

    &__title--small {
        font-family: $font-family--heading;
        font-size: toRem(25);
        font-weight: 700;
        line-height: toRem(30);
        color: $color-dark;
        margin: 0;
        padding: 0;
    }

    &__date {
        font-size: toRem(16);
        font-style: italic;
        font-weight: 400;
        line-height: toRem(25);
        margin: 20px 0;
    }

    &__buttons {
        @include flex($alignItems: center);
        gap: 10px;
    }

    &__button {
        @include size(40px);
        border: 1px solid $color-main;
        border-radius: 4px;
        transition: all $duration ease-in-out;

        svg {
            @include size(100%);
            fill: $color-dark;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            background-color: $color-main;

            svg {
                fill: $color-white;
            }
        }
    }

    &__more {
        @extend %button;
        @include position(absolute, $top: -15px, $right: 15px);

        &:hover, &:focus {
            background-color: $color-light;
            border-color: $color-light;
            color: $color-dark;

            svg {
                fill: $color-dark;
            }
        }
    }
}

//======================================================================================================
// Retour en images
//======================================================================================================

.retour {
    &__title {
        @extend %titleHome;
        color: $color-dark;
    }

    &__items {
        @include flex($alignItems: flex-end, $wrap: wrap, $justifyContent: flex-end);
        gap: 30px;
        margin: -50px 0 0;
        max-height: 470px;
    }

    &__item {
        @include size(fit-content);
        position: relative;

        &:before {
            @include position(absolute, $top: 0, $left: 0);
            @include size(100%);
            content: "";
            background-color: rgba(0, 41, 78, 0);
            border-radius: $border-radius;
            z-index: 0;
            transition: all $duration ease-in-out;
        }

        img {
            @include size(100%);
            object-fit: cover;
            border-radius: $border-radius;
            display: block;
        }

        svg {
            @include position(absolute, $top: 50%, $left: -15px);
            transform: translate(-50%, -50%);
            fill: $color-white;
            opacity: 0;
            @include size(25px);
            transition: all $duration ease-in-out;
        }

        &:nth-child(3), &:nth-child(4) {
            transform: translateY(-250px);
        }

       &--link {
            &:hover, &:focus {
                svg {
                    opacity: 1;
                    left: 50%;
                }

                &:before {
                    background-color: rgba(0, 41, 78, 0.75);
                }
            }
       }
    }
}

//======================================================================================================
// Restons en contact
//======================================================================================================

.networks {
    margin: 100px auto -60px;
    position: relative;
    z-index: 1;

    &__container {
        @include flex($alignItems: flex-end, $justifyContent: space-between);
        background-color: $color-light;
        border-radius: $border-radius;
        padding: 50px;
    }

    &__title {
        font-family: $font-family--heading;
        font-size: toRem(40);
        font-weight: 700;
        line-height: toRem(45);
        margin: 0;
        padding: 0;
        color: $color-dark;
        width: 214px;
    }

    &__content {
        @include flex($direction: column);
        gap: 20px;
        margin: 0 0 0 85px;
    }

    &__text {
        font-size: toRem(18);
        font-weight: 700;
        line-height: toRem(25);
        color: $color-dark;
    }

    &__buttons {
        @include flex($alignItems: center);
        gap: 10px;
    }

    &__button {
        @include flex($alignItems: center, $justifyContent: center);
        @include size(60px);
        border-radius: $border-radius;
        border: 1px solid $color-main;
        transition: all $duration ease-in-out;

        svg {
            @include size(100%);
        }

        &:hover, &:focus {
            background-color: $color-white;
            border-color: $color-white;
        }
    }

    &__app {
        @include flex($alignItems: center, $justifyContent: center, $direction: column);
        gap: 10px;
        @include size(220px, 115px);
        background-color: $color-second;
        border-radius: $border-radius;
        color: $color-white;
        font-size: toRem(14);
        font-weight: 400;
        line-height: toRem(20);
        text-transform: uppercase;
        text-align: center;
        padding: 20px;
        transition: all $duration ease-in-out;

        svg {
            @include size(25px);
            fill: $color-white;
        }

        &:hover, &:focus {
            background-color: $color-main;
            color: $color-white;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .access {
        &__blocs {
            gap: 35px;

            &:before {
                top: -45px;
                left: -100px;
            }
        }

        &__bloc {
            width: 95px;
    
            svg {
                @include size(100%);
            }
    
            &--billetterie {
                @include size(160px, 145px);
                margin-left: 0;
    
                svg {
                    @include size(60px);
                }
            }
        }
    }

    .news {
        &__content {
            row-gap: 30px;
        }
    
        &__items {
            column-gap: 30px;
        }
    
        &__link {
            &--big {
                column-gap: 30px;
            }
    
            &--small {
                width: 455px;
            }
        }
    
        &__image {
            overflow: hidden;
            border-radius: $border-radius;
    
            &--big {
                width: 510px;
                min-width: 510px;
            }
    
            &--small {
                height: 240px;
                position: relative;
            }
        }
    
        .notimg {
            &--big {
                width: 510px;
            }
    
            &--small {
                height: 240px;
            }
        }
    
        &__infos {
            max-width: 400px;
        }
    
        &__titlep {
            &--big {
                font-size: toRem(25);
            }
    
            &--small {
                font-size: toRem(22);
            }
        }
    
        &__button {
            right: 10px;
            top: -15px;
        }
    }

    .home-events {
        padding: 60px 0 170px;
    
        &__container:before {
            top: -40px;
            left: -105px;
        }
    
        &__swiper {
            height: 395px;
        }
    
        &__infos {
            gap: 15px;
            height: 210px;
            padding: 50px 30px 30px;
            justify-content: flex-start;
        }
    
        &__title--small {
            font-size: toRem(22);
        }
    }

    .conseils {
        margin-top: -95px;

        &__container {
            align-items: center;
            height: 200px;
            padding: 35px;
    
            &:before {
                width: 430px;
            }
        }
    
        &__title {
            gap: 27px;
            font-size: toRem(30);
            line-height: toRem(40);
        }
    
        &__content {
            margin-left: 20px;
        }
    
        &__item {
            @include size(350px, 110px);
        }
    
        &__title--small {
            font-size: toRem(22);
        }
    
        &__button {
            @include size(160px, 110px);
            padding: 15px;
        }
    }

    .home-documents {
        margin: 100px 0 80px;

        &__item {
            gap: 20px;
    
            &:first-child, &:last-child {
                .home-documents__image {
                    @include size(255px, 250px);
                }
            }
        }
    
        &__content {
            width: 175px;
        }
    
        &__title--small {
            font-size: toRem(22);
        }
    
        &__more {
            right: 10px;
            top: -20px;
        }
    }

    .retour {
        &__items {
            gap: 20px;
            margin: -45px 0 0;
            max-height: 390px;
        }
    
        &__item {
            &:nth-child(1) {
                @include size(300px, 330px);
            }

            &:nth-child(2) {
                @include size(300px, 390px);
            }

            &:nth-child(3), &:nth-child(4) {
                @include size(300px, 185px);
                transform: translateY(-205px);
            }
        }
    }

    .networks {
        &__container {
            padding: 45px 35px;
        }
    
        &__title {
            font-size: toRem(30);
            line-height: toRem(40);
            width: 165px;
            margin: 0 0 15px 0;
        }
    
        &__content {
            gap: 15px;
            margin: 0 0 5px 45px;
        }
    
        &__app {
            @include size(200px, 110px);
            padding: 10px;
        }
    } 
}


// 960
@media screen and (max-width: $medium) {
    .access {
        position: relative;
        padding: 45px 0 60px;

        &__blocs {
            gap: 30px 60px;
            flex-wrap: wrap;
            justify-content: center;

            &:before {
                bottom: -130px;
                top: unset;
                left: -20px;
            }
        }
    
        &__bloc--billetterie {
            @include size(200px, 145px);
            position: relative;
        }
    }

    .news {
        margin: 70px 0 80px;
    
        &__content {
            row-gap: 20px;
            margin-top: 25px;
        }
    
        &__items {
            column-gap: 20px;
        }
    
        &__link {
            &--big {
                column-gap: 20px;
            }
    
            &--small {
                width: calc(100% - 10px);
            }
        }
    
        &__image {
            overflow: hidden;
            border-radius: $border-radius;
    
            &--big {
                @include size(calc(50% + 30px), 260px);
                min-width: unset;
            }
    
            &--small {
                @include size(100%, 200px);
            }
        }
    
        .notimg {
            &--big {
                @include size(calc(50% + 30px), 260px);
            }
    
            &--small {
                @include size(100%, 200px);
            }
        }
    
        &__infos {
            max-width: calc(50% - 50px);
        }
    
        &__titlep {
            &--big, &--small {
                font-size: toRem(20);
                line-height: toRem(25);
            }
        }
    
        &__intro {
            font-size: toRem(16);
            line-height: toRem(25); 
            margin: 10px 0 20px;
        }
    
        &__button {
            top: -10px;
        }
    }

    .home-events {
        &__container:before {
            left: -125px;
        }
    
        &__top {
            margin: 0 0 25px;
        }
    
        &__navigation {
            &--prev, &--next {
                @include size(50px!important);
            }
        }
    }

    .conseils {
        margin-top: -115px;

        &__container {
            flex-wrap: wrap;
            height: fit-content;
            gap: 30px 25px;

            &:before {
                @include size(100%, 160px);
                background-size: 100%;
                border-radius: $border-radius $border-radius 0 0;
            }
        }

        &__title {
            width: 100%;
            font-size: toRem(25);
            line-height: toRem(30);
            align-items: center;
            gap: 30px;

            svg {
                margin: 0;
            }
        }

        &__content {
            margin-left: 0;
            width: 350px;
        }

        &__button {
            background-color: $color-second;
            width: 255px;
            padding: 0 60px;
        }
    }

    .home-documents {
        margin: 90px 0 80px;
    
        &__items {
            margin: 25px 0 0;
        }
    
        &__item {
            flex-direction: column;
            width: calc(50% - 20px);
        }
    
        &__image {
            @include size(100%!important, 250px!important);
        }
    
        &__content {
            width: 100%;
            margin: 0;
        }

        &__date {
            margin: 10px 0;
        }
    
        &__more {
            right: 10px;
            top: -10px;
        }
    }

    .retour {
        &__title {
            max-width: 200px;
        }

        &__items {
            position: relative;
            flex-wrap: nowrap;
            margin: -40px 0 0;
            max-height: 300px;
        }
    
        &__item {
            &:nth-child(1) {
                @include size(calc(100% / 3), 245px);
            }

            &:nth-child(2) {
                @include size(calc(100% / 3), 300px);
            }

            &:nth-child(3), &:nth-child(4) {
                @include size(calc(100% / 3), 140px);
                transform: translateY(0px);
            }

            &:nth-child(4) {
                @include position(absolute, $bottom: 160px, $right: 0);
                width: calc(100% / 3 - 15px);
            }
        }
    }

    .networks {
        margin: 80px auto -80px;
    
        &__container {
            padding: 35px;
            flex-wrap: wrap;
        }
    
        &__title {
            font-size: toRem(25);
            line-height: toRem(30);
            width: 100%;
            margin: 0;

            br {
                display: none;
            }
        }
    
        &__content {
            margin: 0;
        }
    
        &__button {
            @include size(50px);
        }
    
        &__app {
            @include size(255px, 110px);
            padding: 10px 35px;
        }
    }
}

// 715
@media screen and (max-width: 715px) {
    .home-documents__title {
        max-width: 200px;
    }
}

// 640
@media screen and (max-width: $small) {
    .access {
        padding: 40px 0 60px;

        &__blocs {
            gap: 30px 50px;

            &:before {
                left: -12px;
            }
        }
    }

    .news {
        margin: 60px 0 80px;
    
        &__content {
            gap: 30px;
            margin-top: 15px;
        }
    
        &__items {
            flex-direction: column;
            gap: 20px;
        }
    
        &__link {
            &--big {
                flex-direction: column;
                gap: 10px;
            }
    
            &--small {
                width: 100%;
            }
        }
    
        &__image {
            &--big {
                @include size(100%, 160px);
            }
        }
    
        .notimg {
            &--big {
                @include size(100%, 160px);
            }
        }
    
        &__infos {
            max-width: 100%;
        }

        &__titlep {
            width: 100%;
        }

        &__intro {
            margin: 5px 0 10px;
        }
    
        &__button {
            @include position(relative, $top: unset, $right: unset);
        }
    }

    .home-events {
        padding: 70px 0 205px;
    
        &__container {
            &:before {
                left: -115px;
                top: -50px;
            }
        }

        &__top {
            margin: 0 0 15px;
        }

        &__navigation {
            transform: translateY(-10px);
        }

        &__swiper {
            height: 360px;
        }

        &__infos {
            height: 180px;
        }
    
        &__button {
            @include position(absolute, $bottom: -80px, $left: 10px);
        }
    }

    .conseils {
        margin-top: -65px;

        &__container {
            padding: 35px 25px;
        }

        &__title {
            gap: 15px;

            svg {
                width: 80px;
                height: 50px;
            }
        }

        &__content {
            width: 100%;
        }

        &__item {
            width: 100%;
        }

        &__button {
            width: 100%;
            margin-top: -10px;
        }
    }

    .home-documents {
        margin: 80px 0;

        &__title {
            max-width: 100%;
        }

        &__items {
            margin: 15px 0 0;
            flex-direction: column;
            gap: 30px;
        }

        &__item {
            width: 100%;
        }

        &__more {
            @include position(relative, $top: unset, $right: unset);
            margin: 30px 0 0 !important;
        }
    }

    .retour {
        &__title {
            max-width: 100%;
        }

        &__items {
            flex-direction: column;
            margin: 15px 0 0;
            max-height: unset;
            gap: 10px;
        }
    
        &__item {
            &:nth-child(1) {
                @include size(100%, 195px);
            }

            &:nth-child(2) {
                @include size(100%, 240px);
            }

            &:nth-child(3), &:nth-child(4) {
                @include size(100%, 120px);
            }

            &:nth-child(4) {
                @include position(relative, $bottom: unset, $right: unset);
                width: 100%;
            }
        }
    }

    .networks {
        &__container {
            padding: 35px 25px;
        }

        &__content {
            margin: 20px 0;
        }

        &__text {
            font-weight: 400;
        }
    
        &__button {
            @include size(40px);
        }

        &__app {
            width: 100%;
        }
    }
}
